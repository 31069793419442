import React from "react";
import Ably from "ably";

function App() {
  const [karma, setKarma] = React.useState({
    karma: "Fetching...",
    compare: "none",
  });
  const [ably, setAbly] = React.useState(undefined);
  const [channel, setChannel] = React.useState(undefined);

  React.useEffect(() => {
    const connect = async () => {
      const newAbly = new Ably.Realtime.Promise(
        "yVvang.g9XMHg:LWLDe4AN8FSgDkNC06MsIrH5ZFRMa5Z6ylmG0zVE0vg"
      );
      await newAbly.connection.once("connected");
      setAbly(newAbly);
      console.log("Connected!");
    };
    connect();
  }, []);

  React.useEffect(() => {
    if (ably) {
      setChannel(ably.channels.get(`spezkarmacounter`));
      console.log();
      console.log(`Connected to channel: "spezkarmacounter"`);
    }
  }, [ably]);

  React.useEffect(() => {
    if (channel) {
      channel.subscribe("karmaUpdate", (msg) => {
        const newKarma = msg.data.karma.toLocaleString("en-US");
        const newCompare = msg.data.compare;
        setKarma({ karma: newKarma, compare: newCompare });
        console.log(msg.data);
      });

      return () => {
        channel.unsubscribe("karmaUpdate");
      };
    }
  }, [channel]);

  return (
    <div className="App">
      <div className="wrapper">
        <h1 className="title">
          <a href="https://www.reddit.com/user/spez">u/spez</a> live karma
          counter
        </h1>
        <h1 className={`counter ${karma.compare}`}>{karma.karma}</h1>
        <p>
          This is{" "}
          {karma.compare === "none" ? "the same as" : `${karma.compare} than`}{" "}
          he had last check
        </p>
        <a
          className="fuckspez"
          href="https://www.reddit.com/r/reddit/comments/145bram/addressing_the_community_about_changes_to_our_api/jnk4oz4/?context=3"
        >
          u/spez is a lying pig
        </a>
      </div>
    </div>
  );
}

export default App;
